// libraries
import React, { useState } from 'react';
// components
import HeroModal from 'components/Hero/Modal';
// static
import SpineImg from 'assets/hero-img.png';

const Hero = () => {
  const [isOpenedHeroModal, setIsOpenedHeroModal] = useState(false);

  const closeHeroModal = () => {
    setIsOpenedHeroModal(false);
  };

  const openHeroModal = () => {
    setIsOpenedHeroModal(true);
  };

  return (
    <section className="hero-wrapper">
      <div className="container">
        <div className="hero-info-container">
          <div className="hero-img">
            <img alt="" src={SpineImg} />
          </div>
          <div className="hero-info">
            <div className="hero-subheader">Программа тренировок</div>
            <h1 className="hero-title">Нейромышечное программирование позвоночника</h1>
            <p className="hint">разработана с учетом заботы о здоровье суставов и укреплении спины</p>
            <p className="hero-description">
              Данная программа подходит для людей с начальными или периодическими болями в спине,
              <br />
              а также для людей, ведущих сидячий образ жизни
            </p>

            <div className="hero-buttons-container">
              <button
                className="button filled-button "
                onClick={openHeroModal}
              >
                Подробное о программе
              </button>
              <a className="button outlined-button" href="/#about">Обо мне</a>
              <a className="button outlined-button" href="/#feedbacks">Отзывы</a>
            </div>

            <HeroModal
              closeModalCallback={closeHeroModal}
              isOpened={isOpenedHeroModal}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
