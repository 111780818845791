// libraries
import React from 'react';
// static
import LogoImg from 'assets/logo.png';

const Header = () => (
  <header className="App-header">
    <div className="container">
      <div className="logo">
        <img alt="" src={LogoImg} />
      </div>
      <div className="menu" />
    </div>
  </header>
);

export default Header;
