// libraries
import React, { useState } from 'react';
// components
import WorkoutsModal from 'components/Workouts/Modal';
// statics
import Img2 from 'assets/images/wk-main.png';
import Img1 from 'assets/images/wk1.png';
import Img3 from 'assets/images/wk3.png';
import Img4 from 'assets/images/wk4.png';
import Img5 from 'assets/images/wk5.png';
import Img6 from 'assets/images/wk6.png';

const Workouts = () => {
  const [isOpenedModal, setisOpenedModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const closeWorkoutModal = () => {
    setModalType(null);
    setisOpenedModal(false);
  };

  const openWorkoutModal = (type) => {
    setModalType(type);
    setisOpenedModal(true);
  };

  return (
    <section id="workouts">
      <div className="container">
        <h2 className="info-title title">Подбор тренировок под конкретные цели</h2>

        <p className="text-light text-center">
          Ключевым фактором в подборе тренировок под конкретные цели является регулярность
          и последовательность,
          {' '}
          <br />
          {' '}
          также учет индивидуальных особенностей и уровня подготовки.
        </p>

        <WorkoutsModal
          closeModalCallback={closeWorkoutModal}
          isOpened={isOpenedModal}
          type={modalType}
        />

        <div className="workouts-wrapper">
          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('power');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-pink" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img1} />
              </div>
              <div className="text">Силовые тренировки</div>
            </div>
          </div>

          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('corrective');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-yellow" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img2} />
              </div>

              <div className="text">Коррекционный тренинг</div>
            </div>
          </div>

          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('endurance');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-pink" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img3} />
              </div>

              <div className="text">Реабилитация сухожилий и мышц</div>
            </div>
          </div>

          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('circular');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-blue" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img4} />
              </div>
              <div className="text">Круговые тренировки</div>
            </div>
          </div>

          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('fatBurning');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-red" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img5} />
              </div>
              <div className="text">Жиросжигающие тренировки</div>
            </div>
          </div>

          <div
            className="workouts-item"
            onClick={() => {
              openWorkoutModal('stretching');
            }}
          >
            <div className="workouts-item-blur-container">
              <div className="workouts-item-blur is-blur-pink" />
            </div>
            <div className="workouts-item-icon">
              <div className="icon">
                <img alt="" src={Img6} />
              </div>
              <div className="text">Набор мышечной массы</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Workouts;
