// libraries
import React from 'react';
// components
import About from 'components/About';
import Address from 'components/Address';
import Contacts from 'components/Contacts';
import Feedbacks from 'components/Feedbacks';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Workouts from 'components/Workouts';

const App = () => (
  <div className="app">
    <section className="main-section">
      <Header />
      <Hero />
    </section>
    <div className="divider" />
    <About />
    <div className="divider" />
    <Workouts />
    <div className="divider" />
    <Contacts />
    <div className="divider" />
    <Address />
    <div className="divider" />
    <Feedbacks />
    <Footer />
  </div>
);

export default App;
