// libraries
import React, { useMemo } from 'react';
import ContactsLinks from 'components/shared/ContactsLinks';
// components
import Modal from 'components/shared/Modal';
import WorkoutsModalCircular from 'components/Workouts/Modal/Circular';
import WorkoutsModalCorrective from 'components/Workouts/Modal/Corrective';
import WorkoutsModalFatBurning from 'components/Workouts/Modal/FatBurning';
import WorkoutsModalMassGain from 'components/Workouts/Modal/MassGain';
import WorkoutsModalPower from 'components/Workouts/Modal/Power';
import WorkoutsModalRehabilitation from 'components/Workouts/Modal/Rehabilitation';

const config = {
  power: {
    title: 'Силовые тренировки',
    body: <WorkoutsModalPower />,
  },
  corrective: {
    title: 'Коррекционный тренинг',
    body: <WorkoutsModalCorrective />,
  },
  stretching: {
    title: 'Набор мышечной массы',
    body: <WorkoutsModalMassGain />,
  },
  fatBurning: {
    title: 'Жиросжигающие тренировки',
    body: <WorkoutsModalFatBurning />,
  },
  circular: {
    title: 'Круговые тренировки',
    body: <WorkoutsModalCircular />,
  },
  endurance: {
    title: 'Реабилитация сухожилий и мышц',
    body: <WorkoutsModalRehabilitation />,
  },
};

const WorkoutsModal = ({
  closeModalCallback, isOpened, type,
}) => {
  const workout = useMemo(() => config[type], [type]);

  if (!workout) {
    return null;
  }

  return (
    <Modal
      closeModalCallback={closeModalCallback}
      containerClass="confirmation-modal"
      isOpened={isOpened}
      title={workout.title}
    >
      {workout.body}

      <div className="divider-green-short center" />

      <ContactsLinks type="MODAL" />
    </Modal>
  );
};

export default WorkoutsModal;
