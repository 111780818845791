// libraries
import React, { useCallback, useMemo, useRef } from 'react';
// hooks
import useEventListener from 'hooks/useEventListener';
// static
import { ReactComponent as CloseIcon } from 'assets/icon-close.svg';

const escapeKeyCode = 27;

const Modal = ({
  children, closeModalCallback, isOpened, title, containerClass, positionClass = '',
}) => {
  const modalOverlayContainerRef = useRef();

  const handleOverlayClick = useCallback((e) => {
    if (!closeModalCallback || (e.target !== modalOverlayContainerRef.current)) {
      return;
    }

    closeModalCallback();
  }, [closeModalCallback]);

  const handleKeyDown = useCallback((event) => {
    if (closeModalCallback && (event.keyCode === escapeKeyCode)) {
      closeModalCallback();
    }
  }, [closeModalCallback]);

  useEventListener('keydown', handleKeyDown);

  const closeButton = useMemo(() => {
    if (!closeModalCallback) {
      return null;
    }

    return (
      <div className="modal-close-button" onClick={closeModalCallback}>
        <CloseIcon />
      </div>
    );
  }, [closeModalCallback]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <div
        ref={modalOverlayContainerRef}
        className={`modal-overlay ${positionClass}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${containerClass || ''}`}>
          <div className="modal-header">
            {title && (
            <div className="modal-title">
              {title}
            </div>
            )}
            {closeButton}
          </div>

          <div className={`modal-body ${children ? '' : 'is-hidden'}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
