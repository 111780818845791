// libraries
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import { Navigation } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// statics
import Feedback1 from 'assets/feedbacks/IMG_1501.jpg';
import Feedback2 from 'assets/feedbacks/IMG_1502.jpg';
import Feedback3 from 'assets/feedbacks/IMG_1503.jpg';
import Feedback4 from 'assets/feedbacks/IMG_1504.jpg';
import Feedback5 from 'assets/feedbacks/IMG_1505.jpg';
import Feedback6 from 'assets/feedbacks/IMG_1506.jpg';
import Feedback7 from 'assets/feedbacks/IMG_1507.jpg';
import Feedback8 from 'assets/feedbacks/IMG_1639.jpg';
import Feedback9 from 'assets/feedbacks/IMG_1640.jpg';
import Feedback10 from 'assets/feedbacks/IMG_1641.jpg';
import Feedback11 from 'assets/feedbacks/IMG_1659.jpg';
import Feedback12 from 'assets/feedbacks/IMG_1660.jpg';
import Feedback13 from 'assets/feedbacks/IMG_1730.jpg';
import Feedback14 from 'assets/feedbacks/IMG_1731.jpg';
import Feedback15 from 'assets/feedbacks/IMG_1732.jpg';
import Feedback17 from 'assets/feedbacks/IMG_1806.jpg';
import Feedback18 from 'assets/feedbacks/IMG_1807.jpg';
import Feedback16 from 'assets/feedbacks/IMG_1822.jpg';
import Feedback19 from 'assets/feedbacks/IMG_1823.jpg';
import Feedback20 from 'assets/feedbacks/IMG_1903.jpg';
import Feedback21 from 'assets/feedbacks/IMG_1907.jpg';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

const feedbacks = [
  Feedback21,
  Feedback20,
  Feedback19,
  Feedback18,
  Feedback17,
  Feedback16,
  Feedback15,
  Feedback14,
  Feedback13,
  Feedback12,
  Feedback11,
  Feedback10,
  Feedback9,
  Feedback8,
  Feedback1,
  Feedback2,
  Feedback3,
  Feedback4,
  Feedback5,
  Feedback6,
  Feedback7,
];

const Feedbacks = () => (
  <section className="info" id="feedbacks">
    <div className="container">
      <h2 className="info-title title">Отзывы моих клиентов</h2>

      <div className="feedback-wrapper">
        <Swiper
          className="mySwiper"
          modules={[Navigation]}
          navigation
          slidesPerView="auto"
          spaceBetween={30}
        >
          {feedbacks.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`img-${index}`}>
              <img alt="" src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </section>
);

export default Feedbacks;
