// libraries
import React from 'react';
// helpers
import { scrollPageToTop } from 'helpers/scrollToTop';
// static
import { ReactComponent as ArrowUp } from 'assets/icons/angle-circle-up.svg';

const Footer = () => (
  <footer>
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-xs-6">
            ©
            {' '}
            {(new Date()).getFullYear()}
            {' '}
            Bolotnov Alexey
          </div>
          <div className="col-md-5 col-xs-6 text-right">
            <span className="scroll-to-top scroll-up" onClick={scrollPageToTop}>
              <ArrowUp />
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
