const headerHeight = 0;
const x = 0;
const y = 0;

const scrollToTop = (ref) => {
  if (!ref) {
    window.scrollTo(x, y);

    return;
  }

  if (!window.matchMedia('(max-width: 991px)').matches) {
    ref.scrollIntoView();

    return;
  }

  window.scrollTo({
    top: (ref.offsetTop + headerHeight),
  });
};

export default scrollToTop;

export const scrollPageToTop = (e) => {
  e.preventDefault();
  document.querySelector('#root').scrollIntoView({ behavior: 'smooth' });
};
