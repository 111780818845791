import React from 'react';

const WorkoutsModalFatBurning = () => (
  <>
    <div className="modal-description">
      Жиросжигающие тренировки - это форма
      физической активности, направленная на увеличение расхода энергии и ускорение процесса сжигания жира в организме.
      Эти тренировки обычно сочетают в себе кардио-упражнения с высокой интенсивностью, силовые упражнения и интенсивные
      интервальные тренировки.
    </div>
    <p>Вот ключевые аспекты жиросжигающих тренировок:</p>
    <ol>
      <li>
        <p>
          <strong>Интенсивность:</strong>
          {' '}
          Тренировки проводятся с высокой интенсивностью, что способствует увеличению
          сердечно-сосудистой активности и ускорению метаболизма, что помогает сжигать больше калорий и жиров.
        </p>
      </li>
      <li>
        <p>
          <strong>Кардио и интервалы:</strong>
          {' '}
          Жиросжигающие тренировки включают в себя кардио-упражнения, такие как
          бег, скакалка, велотренажер или прыжки, которые проводятся в формате интервальных тренировок с чередованием
          высокой и низкой интенсивности.
        </p>
      </li>
      <li>
        <p>
          <strong>Силовые упражнения:</strong>
          {' '}
          Добавление силовых упражнений в жиросжигающие тренировки помогает
          увеличить мышечную массу, что в свою очередь повышает метаболический эффект и способствует сжиганию жира даже в
          покое.
        </p>
      </li>
      <li>
        <p>
          <strong>Круговые тренировки:</strong>
          {' '}
          Часто жиросжигающие тренировки организуются в форме круговых
          тренировок, где выполняются серии упражнений подряд с минимальным временем отдыха между ними.
        </p>
      </li>
      <li>
        <p>
          <strong>Длительность и частота:</strong>
          {' '}
          Рекомендуется проводить жиросжигающие тренировки не менее 3-4 раз
          в неделю, при этом длительность тренировки может варьироваться от 20 до 60 минут в зависимости от интенсивности
          и вида упражнений.
        </p>
      </li>
    </ol>
    <p>
      Преимущества жиросжигающих тренировок включают в себя увеличение выносливости, улучшение сердечно-сосудистого
      здоровья, ускорение обмена веществ и, конечно, сжигание жира. Такие тренировки могут быть адаптированы для разных
      уровней подготовки и могут быть выполнены как в тренажерном зале, так и дома.
    </p>
  </>
);

export default WorkoutsModalFatBurning;
