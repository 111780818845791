import React from 'react';
// static
import PersonImg from 'assets/me.jpg';

const About = () => (
  <section className="info" id="about">
    <div className="container">
      <h2 className="info-title title">Обо мне</h2>

      <div className="person-wrapper">
        <div className="person-image-wrapper">
          <div className="person-image">
            <img alt="" src={PersonImg} />
          </div>
        </div>

        <div className="person-text">
          <div className="person-text-about-me">
            <p className="username">
              Привет! Меня зовут
              {' '}
              <span className="text-light">
                Алексей Болотнов.
                {' '}
                <span className="nowrap">Я – фитнес-тренер.</span>
              </span>
            </p>
            <p>
              <span className="text-light">Время</span>
              {' '}
              – это самый главный ресурс, и чем старше становишься, тем больше начинаешь его
              {' '}
              <span className="text-light">ценить.</span>
            </p>

            <p>
              Многие люди проводят годы в тренажёрных залах, но так и не видят изменений в своей осанке,
              ягодицы остаются плоскими, а спина не выдерживает собственного веса стоя в наклоне.
              А ведь именно эти мышцы должны быть крепкими и выносливыми для
              {' '}
              <span className="text-light">поддержания опорно-двигательного аппарата</span>
              .
            </p>

            <p className="text-light">Я помогу Вам разобраться в этом, используя только подходящие для Вас методики.</p>
          </div>
          <div className="info-item">
            <div className="info-item-title">Опыт работы</div>
            <div className="divider-green-short" />
            <ul>
              <li>
                Тренерский стаж более 16 лет:
                <ul>
                  <li>Коррекционный тренинг (осанка)</li>
                  <li>Разработка индивидуальных программ тренировок для клиентов всех уровней подготовки</li>
                  <li>Проведение групповых тренировок в спортзале и на улице</li>
                  <li>Проведение персональных тренировок для клиентов разных возрастов (14-65 лет)</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="info-item">
            <div className="info-item-title">Образование</div>
            <div className="divider-green-short" />
            <ul>
              <li>БНТУ, Минск — Спортивная инжерения</li>
              <li>УО БГУФК — Курс по бодибилдингу</li>
              <li>Академия ДПО, Тренер-преподаватель (Профессиональная подготовка «Тренерская работа»)</li>
              <li>Кинезио — «Мануальное мышечное тестирование»</li>
              <li>Кинезио — «Массаж стоп»</li>
            </ul>
          </div>

          <div className="info-item">
            <div className="info-item-title">Cоревнования</div>
            <div className="divider-green-short" />
            <ul>
              <li>КМС по бодибилдингу и фитнесу;</li>
              <li>Двукратный чемпион Европы 2012г по фитнесу;</li>
              <li>Чемпион Кубка Гродно 2012г по модельному фитнесу;</li>
              <li>Призер Кубка &quot;Железного Льва&quot; 2018г в пряжном бодибилдинге (свыше 180см);</li>
              <li>Призер Чемпионата РБ по фитнесу 2011г;</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </section>
);

export default About;
