// libraries
import React from 'react';
// components
import ContactsLinks from 'components/shared/ContactsLinks';

const Contacts = () => (
  <section className="info" id="contants">
    <div className="container">
      <h2 className="info-title title left">Контакты</h2>
      <ContactsLinks />
    </div>
  </section>
);

export default Contacts;
