// libraries
import React from 'react';
// statics
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as ViberIcon } from 'assets/icons/viber.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsapp.svg';
import ChatImg from 'assets/images/chat.png';

const ContactsLinks = ({ type }) => {
  const isModal = (type === 'MODAL');

  return (
    <div className={isModal ? 'is-modal' : ''}>
      {isModal && (
        <div className="contacts-title">Контакты для связи</div>
      )}

      <div className="contacts-wrapper">
        <div className="contacts-item contacts-links">
          <div className="text-light">
            Записаться на тренировку или задать интересующие Вас вопросы можно в мессенджере:
          </div>
          <div className="contacts-links-wrapper">
            <div className="contacts-links-item telegram">
              <a href="tg://resolve?domain=bolotnov_alexey">
                <TelegramIcon className="icon" />
              </a>
            </div>
            <div className="contacts-links-item whatsapp">
              <a href="whatsapp://send?phone=375296040524&text=">
                <WhatsAppIcon className="icon" />
              </a>
            </div>
            <div className="contacts-links-item viber">
              <a href="viber://chat?number=%2B375296040524">
                <ViberIcon className="icon" />
              </a>
            </div>
          </div>

          <div className="text-light">Либо жду Ваших вопросов и комментариев в социальных сетях:</div>
          <div className="contacts-links-wrapper">
            <div className="contacts-links-item instagram">
              <a href="https://www.instagram.com/trener_bolotnov/">
                <InstagramIcon className="icon" />
              </a>
            </div>
            <div className="contacts-links-item facebook">
              <a href="https://www.facebook.com/profile.php?id=100013930297794&locale=ru_RU">
                <FacebookIcon className="icon" />
              </a>
            </div>
          </div>

          <div className="contacts-links-wrapper">
            <div className="phone">
              Контактый телефон (А1):
              {' '}
              <a href="tel:+375296040524"> +37529 604 05 24</a>
            </div>
          </div>
        </div>

        {!isModal && (
          <div className="contacts-item contacts-chat">
            <img alt="Chat" src={ChatImg} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactsLinks;
