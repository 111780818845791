import React from 'react';

const WorkoutsModalCorrective = () => (
  <>
    <div className="modal-description">
      Боль - это сигналы тела о том, что оно не справляется с нагрузкой, даже при ее отсутствии.
      Это следствие произошедшего сбоя в осанке (отклонение от нормальной линии).
      Там где есть дискомфорт, мышцы перенапряжены, и мозг посылает сигналы о
      {' '}
      <strong>необходимости скорректировать работу мышц.</strong>
    </div>

    <p>
      Коррекционный тренинг (также известный
      как коррекционная гимнастика или коррекционная тренировка) - это специализированный вид физической активности,
      направленный на исправление и улучшение дисбалансов, дефектов в осанке, иных нарушений позы или двигательных
      функций
      тела. Он часто используется в реабилитации после травм, для профилактики и лечения различных заболеваний
      опорно-двигательной системы и коррекции статических и динамических дефектов осанки.
    </p>
    <p>
      Основные принципы коррекционного тренинга включают:
    </p>
    <ol>
      <li>
        <p>
          <strong>Анализ позы и движений:</strong>
          {' '}
          Перед началом программы коррекционного тренинга проводится анализ
          позы и движений, чтобы выявить дисбалансы и дефекты, требующие коррекции.
        </p>
      </li>
      <li>
        <p>
          <strong>Упражнения для укрепления слабых мышц:</strong>
          {' '}
          Коррекционные упражнения часто направлены на
          укрепление слабых мышц, которые могут быть причиной дисбалансов или деформаций в позе.
        </p>
      </li>
      <li>
        <p>
          <strong>Растяжка и гибкость:</strong>
          {' '}
          Помимо укрепления мышц, коррекционный тренинг часто включает в себя
          упражнения на растяжку и улучшение гибкости, чтобы исправить ограничения в движении и улучшить позу.
        </p>
      </li>
      <li>
        <p>
          <strong>Работа над осанкой:</strong>
          {' '}
          Особое внимание уделяется правильному выравниванию и поддержанию
          правильной осанки в повседневной жизни и во время выполнения упражнений.
        </p>
      </li>
      <li>
        <p>
          <strong>Индивидуальный подход:</strong>
          {' '}
          Программа коррекционного тренинга разрабатывается с учетом
          индивидуальных потребностей и особенностей каждого человека, и может включать в себя различные методы и техники
          в зависимости от характера дефектов.
        </p>
      </li>
    </ol>
    <p>
      Коррекционный тренинг может быть полезен для людей с различными проблемами опорно-двигательной системы, включая
      сколиоз, кифоз, плоскостопие, деформации позвоночника и другие. Он также может быть полезен в качестве
      профилактической меры для поддержания здоровой осанки и предотвращения развития болезней позвоночника и
      суставов.
    </p>
  </>
);

export default WorkoutsModalCorrective;
