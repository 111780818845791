import React from 'react';

const WorkoutsModalCircular = () => (
  <div>
    <div className="modal-description">
      Круговая тренировка - это форма
      физической активности, в которой упражнения выполняются последовательно в виде серий или кругов, с минимальным
      временем для отдыха между ними. Обычно круговые тренировки включают в себя выполнение нескольких упражнений
      различных типов, таких как силовые упражнения, кардио, функциональные движения и растяжка.
    </div>
    <p>
      Основные преимущества круговых тренировок:
    </p>
    <ol>
      <li>
        <p>
          <strong>Множество упражнений:</strong>
          {' '}
          В типичной круговой тренировке включаются различные упражнения,
          направленные на работу с разными группами мышц и аспектами физической формы.
        </p>
      </li>
      <li>
        <p>
          <strong>Высокая интенсивность:</strong>
          {' '}
          Упражнения выполняются с высокой интенсивностью, что способствует
          увеличению сердечно-сосудистой нагрузки и улучшению физической выносливости.
        </p>
      </li>
      <li>
        <p>
          <strong>Короткое время отдыха:</strong>
          {' '}
          Периоды отдыха между упражнениями обычно минимальны, что помогает
          поддерживать высокий уровень интенсивности и максимальную работу мышц во время тренировки.
        </p>
      </li>
      <li>
        <p>
          <strong>Повторяемость:</strong>
          {' '}
          Круговые тренировки обычно повторяются несколько раз, образуя несколько
          кругов, что позволяет работать над выносливостью и устойчивостью к утомлению.
        </p>
      </li>
    </ol>
    <p>
      Преимущества круговых тренировок включают в себя эффективное сжигание калорий, улучшение кардио-сосудистого
      здоровья, развитие мышечной силы и выносливости, а также экономию времени за счет комплексного подхода.
    </p>
    <p>
      Круговые тренировки могут быть настроены для разных уровней подготовки и целей, и могут быть выполняться как в
      тренажерном зале с использованием оборудования, так и дома с использованием собственного веса тела и минимального
      оборудования.
    </p>
  </div>
);

export default WorkoutsModalCircular;
