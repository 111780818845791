// libraries
import React from 'react';

// eslint-disable-next-line max-len
const mapLink = 'https://yandex.ru/map-widget/v1/?um=constructor%3Aa357c4ed5a357b635c742ca121494e2d8f4929035d2e1ab6e6967dbfdd967c64&amp;source=constructor';

const Address = () => (
  <section className="info" id="address">
    <div className="container">
      <h2 className="info-title title">Где проходят тренировки?</h2>
      <div className="address-txt">
        Спортивный клуб &quot;Тотем&quot;` - ул. Максима Богдановича, 78 (недалеко от площади Бангалор)
      </div>

      <div className="map-wrapper">
        <iframe
          height="240"
          id="map"
          src={mapLink}
          title="Map"
          width="100%"
        />
      </div>
    </div>
  </section>
);

export default Address;
