import React from 'react';

const WorkoutsModalMassGain = () => (
  <>
    <div className="modal-description">
      После 30 лет у человека с каждым годом разрушается 1 кг мышц. Работа на увеличение мышечной
      массы становится необходимостью!!! Ведь у мужчин весом 70кг в среднем 30кг мышц, а у женщин весом 60кг – всего
      23кг.
      Если не заниматься физическими упражнениями, то к 60 годам мышечной массы не останется и вовсе –
      опорно-двигательный аппарат не сможет выполнять свою функцию корректно.
    </div>
    <p>
      <strong>Примечание:</strong>
      {' '}
      Перед началом программы рекомендуется проконсультироваться с тренером или специалистом
      по физической подготовке для уточнения вашего текущего состояния здоровья и подбора оптимальной программы.
    </p>
    <p>
      Программа тренировок на набор мышечной
      массы обычно включает в себя силовые упражнения с высокими нагрузками и достаточным количеством пищевых калорий
      для
      поддержания роста и восстановления мышц. Вот пример базовой программы тренировок на набор мышечной массы:
    </p>
    <div className="workouts-item-title">День 1: Нижняя часть тела (ноги и ягодицы)</div>
    <ol>
      <li>Приседания: 4 сета х 8-10 повторений</li>
      <li>Жим ногами на тренажере: 4 сета х 8-10 повторений</li>
      <li>Разгибание ног в тренажере: 3 сета х 10-12 повторений</li>
      <li>Ягодичный мостик: 3 сета х 10 повторений на каждую ногу</li>
      <li>Гиперэкстензия: 3 сета х 12-15 повторений</li>
    </ol>
    <div className="workouts-item-title">День 2: Верхняя часть тела (грудь, спина, плечи)</div>
    <ol>
      <li>Жим штанги лежа: 4 сета х 8-10 повторений</li>
      <li>Тяга штанги к подбородку: 4 сета х 8-10 повторений</li>
      <li>Разведение гантелей на наклонной скамье: 3 сета х 10-12 повторений</li>
      <li>Тяга верхнего блока к груди: 3 сета х 10-12 повторений</li>
      <li>Махи гантелей стоя: 3 сета х 12-15 повторений</li>
    </ol>
    <div className="workouts-item-title">День 3: Отдых</div>
    <div className="workouts-item-title">День 4: Нижняя часть тела (ноги и ягодицы)</div>
    <ol>
      <li>Приседания со штангой: 4 сета х 8-10 повторений</li>
      <li>Румынская тяга: 4 сета х 8-10 повторений</li>
      <li>Выпады с гантелями: 3 сета х 10 повторений на каждую ногу</li>
      <li>Сгибание ног на тренажере: 3 сета х 10-12 повторений</li>
      <li>Гиперэкстензия: 3 сета х 12-15 повторений</li>
    </ol>
    <div className="workouts-item-title">День 5: Верхняя часть тела (грудь, спина, плечи)</div>
    <ol>
      <li>Жим гантелей на наклонной скамье: 4 сета х 8-10 повторений</li>
      <li>Тяга верхнего блока за голову: 4 сета х 8-10 повторений</li>
      <li>Жим гантелей стоя: 3 сета х 10-12 повторений</li>
      <li>Тяга гантелей в наклоне: 3 сета х 10-12 повторений</li>
      <li>Подтягивания широким хватом: 3 сета х максимальное количество повторений</li>
    </ol>
    <div className="workouts-item-title">День 6-7: Отдых</div>
    <p>
      Важно выполнять упражнения с правильной техникой, контролируя скорость и
      диапазон движений. Постепенно увеличивайте нагрузку и веса, чтобы стимулировать рост мышц. Для оптимальных
      результатов рекомендуется сочетать программу тренировок с правильным питанием, включающим достаточное количество
      белка и калорий для роста мышц.
    </p>
  </>
);

export default WorkoutsModalMassGain;
