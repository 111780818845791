// libraries
import { useEffect, useRef } from 'react';

const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef(null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const isEventListenerSupported = element && element.addEventListener;

      if (!isEventListenerSupported) {
        return null;
      }

      const eventListener = (event) => savedHandler.current(event);

      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element],
  );
};

export default useEventListener;
