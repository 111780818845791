// libraries
import React from 'react';
import ContactsLinks from 'components/shared/ContactsLinks';
// components
import Modal from 'components/shared/Modal';

const HeroModal = ({
  closeModalCallback, isOpened,
}) => (
  <Modal
    closeModalCallback={closeModalCallback}
    containerClass="confirmation-modal"
    isOpened={isOpened}
    title={'Программа тренировок "Нейромышечное программирование позвоночника"'}
  >
    <div className="modal-description">
      Программа тренировок
      {' '}
      <strong>Нейромышечное программирование позвоночника</strong>
      {' '}
      разработана с учетом заботы о здоровье и укреплении спины,
      что имеет важное значение для общего самочувствия и профилактики проблем.
    </div>

    <div className="modal-hero-item">
      <h3 className="modal-hero-item-title">Цели программы:</h3>
      <ol>
        <li>
          Укрепление мышц спины, кора и ягодиц, что способствует правильной осанке и предотвращению болей в спине.
        </li>
        <li>Улучшение гибкости и подвижности позвоночника.</li>
        <li>Улучшение кровообращения и общего состояния спины.</li>
        <li>
          Снижение риска травм: укрепление мышц и суставов для уменьшения
          вероятности получения травм в повседневной жизни и при физической активности.
        </li>
        <li>
          Развитие нейронных связей: улучшение координации, баланса и
          сознательного контроля за движениями для повышения эффективности тренировок и повседневной деятельности.
        </li>
        <li>
          Улучшение психоэмоционального состояния: cнижение уровня стресса и
          улучшение настроения благодаря регулярным физическим упражнениям и укреплению тела.
        </li>
        <li>
          Поддержание здорового веса: увеличение мышечной массы и улучшение метаболизма
          для поддержки здорового веса и уменьшения жировой массы.
        </li>
      </ol>
    </div>

    <div className="divider-green-short center" />

    <ContactsLinks type="MODAL" />
  </Modal>
);

export default HeroModal;
