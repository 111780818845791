import React from 'react';
// statics
import RehabilitationImg from 'assets/images/rehabilitation.png';

const WorkoutsModalRehabilitation = () => (
  <>
    <div className="modal-description">
      За последние двадцать лет популярность эксцентрических упражнений возросла.
      Особенно широко они применяются в реабилитации, например, при лечении повреждений
      сухожилия трёхглавой мышцы голени или для профилактики травм мышц задней поверхности бедра.
    </div>
    <img alt="" className="responsive workout-modal-image" src={RehabilitationImg} />
    <p>
      Они включают контролируемое упражнение, при котором мышцы удлиняются под нагрузкой.
      Это помогает укрепить мышцы, улучшить гибкость и восстановить функциональность после травмы.
      Включение эксцентрических упражнений в программу тренировок оптимизирует увеличение мощности, силы и массы мышц,
      длину мышц при развитии усилия, а также координацию движений
    </p>
    <p>
      Независимо от применяемого варианта выполнения эксцентрического упражнения с отягощениями,
      необходимо помнить основное правило: выполнение подхода прекращается, если теряется контроль
      над отягощением в любой точке движения – невозможно выполнить движение с необходимым темпом,
      несмотря на максимальное произвольное усилие. Обычно это проявляется неспособностью замедлить
      движение снаряда или рычага тренажёра ближе к завершению фазы эксцентрического сокращения мышц.
    </p>
  </>
);

export default WorkoutsModalRehabilitation;
