import React from 'react';

const WorkoutsModalPower = () => (
  <>
    <div className="modal-description">
      Цель силовых тренировок может варьироваться от увеличения мышечной массы и силы
      до улучшения спортивной производительности или поддержания здоровья.
      Они могут быть организованы как для начинающих,
      так и для продвинутых уровней подготовки, и могут включать в себя различные типы упражнений, такие как:
    </div>
    <ol>
      <li>
        <p>
          <strong>Тяга:</strong>
          {' '}
          Упражнения, направленные на развитие мышц спины и бицепсов, такие как тяга штанги к
          подбородку или тяга гантелей.
        </p>
      </li>
      <li>
        <p>
          <strong>Толчки:</strong>
          {' '}
          Упражнения, в которых происходит толчковое движение вверх, например, жим штанги
          лежа
          или жим гантелей.
        </p>
      </li>
      <li>
        <p>
          <strong>Приседания:</strong>
          {' '}
          Упражнения, которые включают движения сгибания и разгибания в коленях, такие
          как
          классические приседания со штангой или приседания с гантелями.
        </p>
      </li>
      <li>
        <p>
          <strong>Разгибания:</strong>
          {' '}
          Упражнения, направленные на развитие мышц ног и ягодиц, такие как разгибание
          ног в
          тренажере или выпады.
        </p>
      </li>
      <li>
        <p>
          <strong>Кроссфит:</strong>
          {' '}
          Тренировки, которые комбинируют в себе различные упражнения силовых тренировок с
          аэробными нагрузками и функциональными движениями.
        </p>
      </li>
    </ol>
    <p>
      Силовые тренировки могут быть выполнены как с использованием свободных весов (гантели, штанга), так и на силовых
      тренажерах. Важно подбирать упражнения и вес таким образом, чтобы они соответствовали вашему уровню подготовки и
      целям тренировки, а также обеспечивать правильную технику выполнения для предотвращения травм и максимизации
      результатов.
    </p>
  </>
);

export default WorkoutsModalPower;
